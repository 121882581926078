import svLocale from "date-fns/locale/sv";
import nbLocale from "date-fns/locale/nb";
import nnLocale from "date-fns/locale/nn";
import enLocale from "date-fns/locale/en-US";
import {format, formatDistance, max} from 'date-fns';

export const getLocale = () => {
    const language = window.localStorage.getItem('i18nextLng')

    if(!language) {
        return enLocale
    } else if(language.startsWith("sv")) {
        return svLocale
    } else if (language === 'no' || language === 'nb-NO') {
        return nbLocale
    } else if (language === 'no' || language === 'nn-NO') {
        return nnLocale
    } else {
        return enLocale
    }
}

export const dateAndTime = date => {
    if(!date) {
        return date
    }
    return format(toDateInstance(date), "PPp", {locale: getLocale()})
}

export const onlyDate = date => {
    if(!date) {
        return date
    }
    return format(toDateInstance(date), "P", {locale: getLocale()})
}

export const distanceAgo = date => formatDistance(
    toDateInstance(date),
    new Date(),
    {
        addSuffix: true, locale: getLocale()
    })

export const latestDate = dates => max(dates.map(toDateInstance))

const toDateInstance = date => {
    if(date instanceof Date) {
        return date;
    } else {
        return new Date(date);
    }

}

export const localTime = timestamp => {
    const date = new Date(timestamp)
    return new Date(date.getTime() - date.getTimezoneOffset() * 60000)
}