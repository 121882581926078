import React, {lazy, Suspense} from 'react';
import {WhenHeaderIsReady} from "../header/WhenHeaderIsReady";
import {BrowserRouter as Router} from "react-router-dom";
import {Theme} from "./Theme";
import preval from 'preval.macro'
import {AppProvider} from "./appContext";
import {ProgressIndicator} from "./ProgressIndicator";
import {HeaderProvider, WithHeader} from '@ekultur/header-microfrontend';

const AppBody = lazy(
    () => import('./AppBody')
)

export const App = () => {

    if(!window._env_ || !window._env_.REACT_APP_API_GATEWAY) {
        return null;
    }

    return (
        <Theme>
            <Router>
                <HeaderProvider>
                    <AppProvider>
                        <WithHeader>
                            <WhenHeaderIsReady>
                                <Suspense fallback={<ProgressIndicator />}>
                                    <AppBody />
                                </Suspense>
                            </WhenHeaderIsReady>
                        </WithHeader>
                    </AppProvider>
                </HeaderProvider>
            </Router>
        </Theme>
    )
}