import React, {useCallback} from 'react';
import {Header} from '@ekultur/header-microfrontend';
import {useAuthsDispatch, useAuthsState, USER_LOGGED_OUT} from "../auths/authsContext";
import {login, logout} from "@ekultur/authentication";
import {useLocation} from "react-router-dom";
import {MENU_CLOSED, MENU_OPENED, RENDERED, useAppDispatch, useAppState} from "../app/appContext";
import {themeOptions} from "../app/Theme";
import {CLOSE, OPEN, useMuseumFilterDispatch, useMuseumFilterState} from "@ekultur/museum-filter";
import {ProgressIndicator} from "../app/ProgressIndicator";
import {Box, Toolbar} from "@mui/material";
import {onlyDate} from "../date/dateFormatter";

export const AppHeader = () => {
    const {userIsAuthenticated, userData} = useAuthsState()
    const {museums, apps, menuOpen} = useAppState()
    const dispatchAuths = useAuthsDispatch()
    const appDispatch = useAppDispatch()
    const museumFilterDispatch = useMuseumFilterDispatch()
    const {open} = useMuseumFilterState()
    const location = useLocation();
    const museumSelectorCallback = useCallback(
        () => {
            museumFilterDispatch({type: open ? CLOSE : OPEN})
        },
        [open, museumFilterDispatch]
    )


    const loginHandler = () => {
        const prevPath = location.state && location.state.previousPath ? location.state.previousPath : "/"
        login({
            locationPostLogin: prevPath,
            loginHost: window._env_.REACT_APP_LOGIN_HOST
        })
    }

    const logoutHandler = () => {
        logout({
            onLogout: () => dispatchAuths({type: USER_LOGGED_OUT})
        })
    }

    const actions = () => {
        return userIsAuthenticated ?
            [
                {
                    name: "museumSelector",
                    callback: museumSelectorCallback,
                }
            ] : []
    }

    const readyLoggedIn = 0 < museums.length && 0 < apps.length && Object.keys(userData).length

    const museumsObject = museums.reduce((accumulator, item) => ({
        ...accumulator,
        [item.id]: item
    }), {})

    const appsObject = apps.reduce((accumulator, item) => ({
        ...accumulator,
        [item.id]: item
    }), {})

    const progressIfNotLoggedIn = () => {
        if(userIsAuthenticated && !Boolean(readyLoggedIn)) {
            return (
                <Box sx={{width: "100%"}}>
                    <Toolbar />
                    <ProgressIndicator />
                </Box>
            )
        } else {
            return null
        }
    }

    const menuHandler = () => {
        appDispatch({type: menuOpen ? MENU_CLOSED : MENU_OPENED})
    }

    if(0 === apps.length ) {
        return null
    } else {
        return (
            <Box>
                <Header
                    alwaysShowHamburger={true}
                    appId={window._env_.REACT_APP_ID}
                    loggedIn={Boolean(readyLoggedIn) && userIsAuthenticated}
                    mobileOpenHandler={menuHandler}
                    logoutHandler={() => logoutHandler()}
                    loginHandler={loginHandler}
                    mobileOpen={menuOpen}
                    onRender={() => appDispatch({type: RENDERED})}
                    version={window._env_.REACT_APP_VERSION}
                    buildDate={onlyDate(parseInt(window._env_.REACT_APP_VERSION_DATE))}
                    theme={themeOptions}
                    showMuseums={false}
                    actions={actions()}
                    museums={museumsObject}
                    apps={appsObject}
                    user={userData}
                    showNoAccessPlaceholder={true}
                />
                {progressIfNotLoggedIn()}
            </Box>
        )
    }
}