import React, {useEffect} from 'react';
import {APPS_RECEIVED, useAppDispatch} from "../app/appContext";
import {kitFetch} from "@ekultur/fetch";

export const WithApplications = ({children}) => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        kitFetch('/app-registry/apps/')
            .then(json => dispatch({
                type: APPS_RECEIVED,
                apps: json
            }))
    }, [dispatch])

    return (
        <>
            {children}
        </>
    )

}