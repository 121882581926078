import React from 'react';
import reportWebVitals from './reportWebVitals';
import {App} from "./app/App";
import './app/i18n';
import {createRoot} from 'react-dom/client'
import * as Sentry from "@sentry/react";
import {browserTracingIntegration} from "@sentry/react";

if(window._env_ && window._env_.SENTRY_ENV !== 'local') {
    Sentry.init({
        dsn: "https://9fc6674110934c7ea1f2189f92f83521@o1289125.ingest.sentry.io/6507115",
        integrations: [browserTracingIntegration()],
        tracesSampleRate: 0.2,
        environment: window._env_.SENTRY_ENV,
        enabled: window._env_.SENTRY_ENV !== 'local',
        release: "ekultur-admin@" + window._env_.REACT_APP_VERSION
    });
}

const container = document.getElementById('vm-admin-root')
const root = createRoot(container)
root.render(
    <App/>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
