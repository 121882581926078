import React from 'react';
import {StyledEngineProvider} from "@mui/material/styles";
import {Box, createTheme, ThemeProvider} from "@mui/material";
import {grey} from "@mui/material/colors";


export const Theme = ({children}) => {
    const appTheme = createAppTheme();

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={appTheme}>
                <Box
                    sx={{
                        zIndex: 1,
                        top: 0,
                        right: 0,
                        left: 0,
                        bottom: 0,
                    }}
                >
                    {children}
                </Box>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export const themeOptions = {
    palette: {
        primary: {
            main: "#1b8751",
            light: "#2cac6c",
            dark: '#14643c'
        },
        background: {
            default: grey[100]
        },
        portalButton: {
            main: '#ffffff',
            contrastText: '#000000',
        }
    },
    components: {
        MuiTextField: {
            defaultProps: {
                color: "primary",
                variant: "filled"
            }
        },
        MuiFormLabel: {
            defaultProps: {
                color: "primary"
            }
        },
        MuiFormControl: {
            defaultProps: {
                variant: 'filled',
                color: "primary"
            }
        },
        MuiSelect: {
            defaultProps: {
                variant: 'filled',
                color: "primary"
            }
        },
    }
};

const createAppTheme = () => createTheme(themeOptions);